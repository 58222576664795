.layout-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.layout-nav {
    max-width: 200px;
    height: 100vh;
    background-color: #303030;
    color: #eeeeee;
}

.branding {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 200px;
    height: 80px;

    background-color: #262626;
}

.branding img {
    max-height: 80px;
    max-width: 190px;
    margin-left: 5px;
}

.layout-nav ul {
    padding-left: 0px;
    list-style-type: none;
}

.layout-nav li.menu-item {
    padding-top: .5em;
    padding-bottom: .5em;
}

.layout-nav .section-title {
    font-size: 120%;
    background-color: #007bff;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: .2em;
}

.layout-nav li.active {
    background-color: #202020;
}

.layout-nav a {
    color: #eeeeee;
}

.layout-nav li.section li {
    padding-left: 1em;
}

.layout-content-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.layout-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    height: 80px;

    background-color: #262626;
    color: #eee;
}

.header-title {
    align-self: start;
    flex-grow: 1;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
}
.header-title > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 5px;
}

.header-menu {
    align-self: end;
    flex-grow: 1;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    padding-right: 10px;
}

.header-menu > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 5px;
}

.layout-footer {
    flex: 0 0 auto;
    background-color: #303030;
    color: #ccc;
    padding: .6em;
}

.layout-content-scroller {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-grow: 1;
}

.layout-content {
    flex-grow: 1;
}

.setting-row {
    margin-top: 1em;
    padding-bottom: .3em;
    padding-left: 1em;
}