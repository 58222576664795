.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: dimgrey;
}

.login-form {
    background-color:white;
    padding: 1.5em;


}

.login-form label {
    display: block;
    margin: .5em 0;
}

.login-form input {
    display: block;
    margin: .5em 0;
}